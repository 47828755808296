import React, {Component} from 'react';
import PropTypes from 'prop-types';

class NotFound extends Component {
  render() {
    return (
      <React.Fragment>

      </React.Fragment>
    );
  }
}

NotFound.propsTypes = {
  className: PropTypes.string,
};

export default NotFound
