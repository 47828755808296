import React, {Component} from 'react';
import PropTypes from 'prop-types';
import { Map, Marker, Overlay } from 'pigeon-maps'
import {FormattedMessage} from "react-intl";
import PERSONAL from '../../config/personal';

import './where-are-we.scss';

class WhereAreWe extends Component {
  mapTilerProvider = (x, y, z, dpr) => {
    return `https://a.tile.openstreetmap.org/${z}/${x}/${y}.png`
  }

  render() {
    const {className} = this.props;
    return (
      <div className={`container-fluid pt-5 px-0 where-are-we ${className}`}>
        <div className="container my-5">
          <h3><FormattedMessage id="components.where_are_we.title" /></h3>
        </div>
        <div className="row no-gutters">
          <div className="col-12 col-lg-8">
            <div className="map-container">
              <Map
                provider={this.mapTilerProvider}
                defaultCenter={[49.05148017829175, 3.7815192209562083]}
                defaultZoom={17}
                width={0}
                height={700}
                metaWheelZoom={true}
              >
                <Marker
                  anchor={[49.05148017829175, 3.7815192209562083]}
                  payload={1}
                />
                <Overlay anchor={[49.05148017829175, 3.7815192209562083]} offset={[0, 0]}>
                  <div className="d-flex flex-column p-2 overlay">
                    <div className="font-weight-bold">{PERSONAL.LAST_NAME} {PERSONAL.FIRST_NAME}</div>
                    <div className="text-break">{PERSONAL.ADDRESS.STREET}, {PERSONAL.ADDRESS.REGION}</div>
                    <div className="text-break">{PERSONAL.ADDRESS.POSTAL_CODE}, {PERSONAL.ADDRESS.CITY}</div>
                    <div>{PERSONAL.PHONE_NUMBER}</div>
                  </div>
                </Overlay>
              </Map>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-4">
            <div className="picture" />
          </div>
        </div>
      </div>
    );
  }
}

WhereAreWe.defaultProps = {
  className: '',
}

WhereAreWe.propTypes = {
  className: PropTypes.string,
};

export default WhereAreWe;
