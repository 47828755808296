import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './footer.scss';
import {FormattedMessage} from "react-intl";
import PERSONAL from "../../config/personal";

class Footer extends Component {
  render() {
    const {className} = this.props;
    return (
      <div className={`container-fluid px-0 footer ${className}`}>
        <div className="h-100 d-flex flex-column justify-content-center align-items-center py-5">
          <div className="text-center" itemScope itemType="https://schema.org/Person">
            <div className="font-weight-bold" itemProp="name">{PERSONAL.LAST_NAME} {PERSONAL.FIRST_NAME}</div>
            <div itemProp="jobTitle">
              <FormattedMessage id="work_title" />
            </div>
            <div itemProp="streetAddress">{PERSONAL.ADDRESS.STREET}, {PERSONAL.ADDRESS.REGION}</div>
            <div>{PERSONAL.ADDRESS.POSTAL_CODE} {PERSONAL.ADDRESS.CITY}</div>
            <div className="mt-1" itemProp="telephone">{PERSONAL.PHONE_NUMBER}</div>
            <div className="mt-3 social">
              <a href={PERSONAL.FACEBOOK_LINK} target="_blank" rel="noreferrer">
                <i className="fab fa-facebook" />
              </a>
            </div>
          </div>
          <div className="text-center font-italic warning">
            <FormattedMessage id="components.footer.warning" />
          </div>
        </div>
      </div>
    );
  }
}

Footer.defaultProps = {
  className: '',
}

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
