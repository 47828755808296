import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import BrutPicture from '../../assets/media/bottle-brut-glasses.jpg';
import RosePicture from '../../assets/media/bottle-brut-rose.jpg';
import DemiSecPicture from '../../assets/media/bottle-brut.jpg';
import RANGE from '../../config/range';
import AnimationsFadeIn from "../Animations/FadeIn/AnimationsFadeIn";

const picture = {
  brut: BrutPicture,
  rose: RosePicture,
  demi_sec: DemiSecPicture
}

class OneRange extends Component {
  render() {
    const {left, slug, className} = this.props;
    return (
      <AnimationsFadeIn left={left} duration="700ms">
        <div className={`container ${className}`}>
          <div className="row">
            <div className={`col-12 col-md-3 ${left ? 'order-0' : 'order-1'} col-picture`}>
              <div className="h-100 d-flex justify-content-center align-items-center picture">
                <img src={picture[slug]} alt="bottle" />
              </div>
            </div>
            <div className={`col-12 col-md-9 mt-3 mt-md-0 text-center text-md-left d-flex flex-column ${left ? 'order-1' : 'order-0'}`}>
              <h4><FormattedMessage id={`components.range.${slug}.title`} /></h4>
              <p><FormattedMessage id={`components.range.${slug}.text`} /></p>
              <div className="mt-auto d-flex flex-row details">
                <div className="ml-auto mr-5"><i className="fa fa-wine-bottle" /> {RANGE[slug.toUpperCase()].CONTAIN}</div>
                <div><i className="fa fa-euro-sign" /> {RANGE[slug.toUpperCase()].PRICE}</div>
              </div>
            </div>
          </div>
        </div>
      </AnimationsFadeIn>
    );
  }
}

OneRange.defaultProps = {
  className: '',
  left: true,
}

OneRange.propTypes = {
  className: PropTypes.string,
  left: PropTypes.bool,
  slug: PropTypes.oneOf(['brut', 'rose', 'demi_sec']).isRequired,
};

export default OneRange;
