import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import OneRange from "./OneRange";

import './range.scss';

class Range extends Component {
  render() {
    const {className} = this.props;
    return (
      <div className={`container-fluid px-0 range ${className}`}>
        <div className="container my-5">
          <h3><FormattedMessage id="components.range.title" /></h3>
        </div>
        <OneRange slug="brut" left />
        <OneRange slug="rose" left={false} />
        <OneRange slug="demi_sec" left />
      </div>
    );
  }
}

Range.defaultProps = {
  className: '',
}

Range.propTypes = {
  className: PropTypes.string,
};

export default Range;
