import React, {Component} from 'react';
import SplashHeader from "../../components/SplashHeader/SplashHeader";
import Presentation from "../../components/Presentation/Presentation";
import Range from "../../components/Range/Range";
import WhereAreWe from "../../components/WhereAreWe/WhereAreWe";
import Footer from "../../components/Footer/Footer";
import Helmet from "react-helmet";
import PERSONAL from '../../config/personal';
import {injectIntl} from "react-intl";

const structuredJSON = {
  "@context": "https://schema.org",
  "@type": "Person",
  "address": {
    "@type": "PostalAddress",
    "addressLocality": PERSONAL.ADDRESS.STREET,
    "addressRegion": PERSONAL.ADDRESS.REGION,
    "postalCode": PERSONAL.ADDRESS.POSTAL_CODE,
    "streetAddress": PERSONAL.ADDRESS.STREET,
  },
  "jobTitle": "Vigneron récoltant",
  "name": `${PERSONAL.LAST_NAME} ${PERSONAL.FIRST_NAME}`,
  "telephone": PERSONAL.PHONE_NUMBER,
  "url": "https://champagnemailletsebastien.com"
};

class Home extends Component {
  render() {
    return (
      <React.Fragment>
        <SplashHeader />
        <Presentation />
        <Range />
        <WhereAreWe />
        <Footer />
        <Helmet>
          <meta charSet="utf-8" />
          <html lang={this.props.locale} />
          <meta
            name="description"
            content={this.props.intl.messages['meta.description']}
          />
          <title>
            Champagne {PERSONAL.LAST_NAME.toUpperCase()} {PERSONAL.FIRST_NAME} - {this.props.intl.messages['work_title']}
          </title>
          <script className='structured-data-list' type="application/ld+json">{JSON.stringify(structuredJSON)}</script>
        </Helmet>
      </React.Fragment>
    );
  }
}

export default injectIntl(Home);
