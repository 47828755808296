const range = {
  BRUT: {
    CONTAIN: '75cl',
    PRICE: '16',
  },
  ROSE: {
    CONTAIN: '75cl',
    PRICE: '17',
  },
  DEMI_SEC: {
    CONTAIN: '75cl',
    PRICE: '16',
  }
};

export default range;
