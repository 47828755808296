import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './animations-fade-in.scss';

class AnimationsFadeIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      offset: 0,
    }
    this.ref = React.createRef();
  }

  onScroll = () => {
    const {active} = this.state;
    const {top} = this.ref.current.getBoundingClientRect();
    const topPosition = top + window.scrollY;
    if(window.scrollY > topPosition - (window.innerHeight*.80)) {
      if(!active) {
        this.setState({active: true});
      }
    } else if(this.props.scrollUp && window.scrollY <= topPosition - window.innerHeight) {
      if(active) {
        this.setState({active: false});
      }
    }
  }

  componentDidMount() {
    if(this.props.left) {
      const {width,left} = this.ref.current.getBoundingClientRect();
      this.setState({offset: width+left});
    } else {
      const {width,right} = this.ref.current.getBoundingClientRect();
      this.setState({offset: width+right});
    }
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount(){
    window.removeEventListener('scroll', this.onScroll);
  }

  render() {
    const {duration, left} = this.props;
    const {active, offset} = this.state;
    return (
      <div className="position-relative overflow-hidden">
        <div
          ref={this.ref}
          style={{
            transition: `transform ${duration} linear`,
            transform: `translateX(${left  ? '-':'+'}${active ? 0 : offset}px)`
          }}
        >
          {
            React.cloneElement(this.props.children)
          }
        </div>
      </div>
    );
  }
}

AnimationsFadeIn.defaultProps = {
  className: '',
  duration: '500ms',
  left: true,
  scrollUp: false,
}

AnimationsFadeIn.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.string,
  left: PropTypes.bool,
  scrollUp: PropTypes.bool,
};

export default AnimationsFadeIn;
