import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";

import './presentation.scss';

class Presentation extends Component {
  render() {
    const {className} = this.props;
    return (
      <div className={`container-fluid px-0 presentation ${className}`}>
        <div className="container my-5">
          <h3><FormattedMessage id="components.presentation.title" /></h3>
        </div>
        <div className="row no-gutters">
          <div className="col-12 col-lg-8">
            <div className="h-100 welcome" />
          </div>
          <div className="col-12 col-lg-4 mt-3">
            <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center px-5">
              <div className="mb-5 text-center">
                <FormattedMessage id="components.presentation.text_welcome" />
              </div>
              <i className="fa fa-glass-cheers ic-cheers" />
              <div className="mt-5 text-center">
                <FormattedMessage id="components.presentation.text_explain" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Presentation.defaultProps = {
  className: '',
}

Presentation.propTypes = {
  className: PropTypes.string,
};

export default Presentation;
