const personal = {
  LAST_NAME: 'Maillet',
  FIRST_NAME: 'Sébastien',
  ADDRESS: {
    STREET: '3 rue du peigne d\'argent',
    REGION: 'Chêne-La-Reine',
    POSTAL_CODE: '51700',
    CITY: 'LEUVRIGNY'
  },
  PHONE_NUMBER: '(+33) 03 26 59 72 24',
  FACEBOOK_LINK: 'https://www.facebook.com/Champagne-Maillet-Sebastien-1631694017070979/',
};

export default personal;
