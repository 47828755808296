import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {RedirectAs404} from "./RedirectAs404";
import NotFound from "../routes/NotFound/NotFound";
import Home from "../routes/Home/Home";

class MainRouter extends Component {
  render() {
    return (
      <div className="main-container">
        <Switch>
          <Route exact path="/" component={(props) => <Home {...props} locale={this.props.locale}/>} />
          <Route path="/not-found" component={NotFound} />
          <Route component={RedirectAs404} />
        </Switch>
      </div>
    );
  }
}

export default MainRouter;
