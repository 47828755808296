import React, {Component} from 'react';
import {IntlProvider} from "react-intl";
import {Router, Route, Switch} from "react-router-dom";
import messages from './translations';
import {getLocale, getLanguage} from './libraries/locale';
import history from './libraries/history'

import MainRouter from "./routers/MainRouter";
import './style/base.scss';

class App extends Component {
  render() {
    const language = getLanguage();
    const locale = getLocale();
    return (
      <IntlProvider locale={locale} messages={messages[language]}>
        <Router history={history}>
          <Switch>
            <Route component={(props) => <MainRouter {...props} locale={locale} />} />
          </Switch>
        </Router>
      </IntlProvider>
    );
  }
}

export default App;
