import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import PERSONAL from '../../config/personal';

import './splash-header.scss';

class SplashHeader extends Component {
  render() {
    const {className} = this.props;
    return (
      <div className={`d-flex flex-column position-relative vh-100 splash-header ${className}`}>
        <div className="position-absolute w-100 h-100 background" />
        <div className="position-absolute d-flex flex-column h-100 w-100 justify-content-center align-items-center text">
          <div className="d-flex flex-column align-items-center brand mt-5">
            <h2 className="type">
              <FormattedMessage id="components.splash_header.text.champagne" />
            </h2>
            <h1 className="name text-center">
              {PERSONAL.LAST_NAME} {PERSONAL.FIRST_NAME}
            </h1>
          </div>
          <div className="work-title text-center">
            <FormattedMessage id="work_title_long" />
          </div>
          <div className="d-flex flex-column align-items-center mb-3 mt-auto mx-2 text-center">
            <div className="address">{PERSONAL.ADDRESS.STREET}, {PERSONAL.ADDRESS.REGION}</div>
            <div className="address">{PERSONAL.ADDRESS.POSTAL_CODE} {PERSONAL.ADDRESS.CITY}</div>
            <div className="mt-1">{PERSONAL.PHONE_NUMBER}</div>
            <div className="mt-3 social">
              <a href={PERSONAL.FACEBOOK_LINK} target="_blank" rel="noreferrer">
                <i className="fab fa-facebook" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SplashHeader.defaultProps = {
  className: '',
}

SplashHeader.propsTypes = {
  className: PropTypes.string,
};

export default SplashHeader;
